import Button from '@atlaskit/button';
import { Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { CircularProgress } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';
import api from '~/services/api';
import { upperCase } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { FaRegWindowRestore } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { ConfirmButton } from '../Buttons';
import { Span } from '../Span';
import {
  AlertBox,
  BtnGroupContainer,
  ContainerGrid,
  FilterConta,
  GrupButtons,
  InputSearch,
} from './styles';

interface FormProps {
  value: string | number | undefined;
  isRequired?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  onChange(data: number, data1: boolean, codConta: number): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
}
type Conta = {
  cod_conta: number;
  conta_contabil: string;
  conta_reduzida: string;
  des_conta_contabil: string;
};
type Contas = {
  id: number;
  conta_contabil: string;
  conta_reduzida?: string;
  des_conta_contabil: string;
};
/**
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 * @value Valor correspondente ao cod_conta
 */
const ContaContabil: React.FC<FormProps> = (props) => {
  const {
    value,
    isRequired = false,
    onChange,
    min = 100,
    max = 999,
    maxLength = 3,
    isUndefined = false,
    isNull = null,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
  } = props;
  const [inicializado, setInicializado] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tipoFiltro, setTipoFiltro] = useState<number>(1);
  const [filtro, setFiltro] = useState<string>('');
  const [selectedConta, setSelectedConta] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [mensagemBusca, setMensagemBusca] = useState<string>('');
  const [valueInput, setValueInput] = useState<any>(0);
  const [valueAlt, setValueAlt] = useState<number | string>();

  const [conta, setConta] = useState<Conta>({
    cod_conta: 0,
    conta_contabil: '',
    conta_reduzida: '',
    des_conta_contabil: '',
  });

  const [contas, setContas] = useState<Array<Contas> | []>([]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'conta_contabil',
      headerName: 'Conta Contábil',
      width: 190,
      editable: false,
    },
    {
      field: 'conta_reduzida',
      headerName: 'Reduzida',
      width: 190,
      editable: false,
    },
    {
      field: 'des_conta_contabil',
      headerName: 'Descrição',
      width: 480,
      editable: false,
    },
  ];

  useEffect(() => {
    if (value === 0) {
      setConta({
        cod_conta: 0,
        conta_contabil: '',
        conta_reduzida: '',
        des_conta_contabil: '',
      });
    }
  }, [value]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    if (row === null) {
      return toast.warning(
        'Clique sobre a Conta Contábil que deseja selecionar!',
      );
    }
    setSelectedConta(row);
  };
  const validation = useCallback(
    (_newValue: string) => {
      let newValue = Number.parseInt(_newValue, 10);
      if (_newValue !== newValue.toString()) {
        _newValue = _newValue.replace(/^[0]+/g, '');
        newValue = Number.parseInt(_newValue, 10);
      }
      if (inicializado && isRequired) {
        if (isUndefined && newValue === undefined) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
        if (isNull && newValue === null) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
      }
      if (inicializado) {
        if (newValue < min) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
        if (newValue > max) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
      }

      setValueInput(newValue);

      setIsInvalid(false);
      setInicializado(true);
    },
    [
      conta,
      inicializado,
      isNull,
      isRequired,
      isUndefined,
      max,
      min,
      onChange,
      setValueInput,
    ],
  );

  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  const handleContas = useCallback(async () => {
    setMensagemBusca('');
    const valueParseString = String(valueInput);

    if (valueParseString.length > 2) {
      try {
        const { data } = await api.get(`/contaContabil/${valueParseString}`);

        if (data.success) {
          setConta({
            cod_conta: data.data[0].cod_conta,
            conta_contabil: data.data[0].conta_contabil,
            conta_reduzida: data.data[0].conta_reduzida,
            des_conta_contabil: data.data[0].des_conta_contabil,
          });
          onChange(valueInput, false, data.data[0].cod_conta);
        }
      } catch (error: any) {
        setConta({
          cod_conta: 0,
          conta_contabil: '',
          conta_reduzida: '',
          des_conta_contabil: '',
        });
        setMensagemBusca('Conta contábil não encontrada');
        onChange(Number(value), true, 0);
      }
    }
  }, [valueInput]);

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleContasFiltros = useCallback(async () => {
    if (String(filtro).length >= 1) {
      setLoading(true);
    }
    try {
      const { data } = await api.get(
        `contaContabil/conta/${tipoFiltro}?campo=${upperCase(filtro)}`,
      );

      if (data.success) {
        const contasFormatada: any = [];
        // eslint-disable-next-line array-callback-return
        data.data.map((element: any) => {
          contasFormatada.push({
            id: element.cod_conta,
            conta_contabil: element.conta_contabil,
            conta_reduzida: element.conta_reduzida,
            des_conta_contabil: element.des_conta_contabil,
          });
        });
        setContas(contasFormatada);
      }
    } catch (error: any) {
      setContas([]);
    }
    setLoading(false);
  }, [filtro, tipoFiltro]);

  useEffect(() => {
    handleContas();
  }, [handleContas, valueInput]);

  useEffect(() => {
    handleContasFiltros();
  }, [handleContasFiltros, filtro]);

  const handleConfirm = useCallback(() => {
    if (selectedConta.id) {
      setConta({
        cod_conta: selectedConta.id,
        conta_contabil: selectedConta.conta_contabil,
        conta_reduzida: selectedConta.conta_reduzida,
        des_conta_contabil: selectedConta.des_conta_contabil,
      });
      onChange(selectedConta.conta_reduzida, false, selectedConta.id);
      setValueAlt(selectedConta.conta_reduzida);
      setShowModal(false);
      toast.success(
        `Conta Contábil ${selectedConta.conta_reduzida} foi adicionada`,
      );
    } else {
      toast.warning('Clique sobre a Conta Contábil que deseja selecionar!');
    }
  }, [
    onChange,
    selectedConta.conta_contabil,
    selectedConta.conta_reduzida,
    selectedConta.des_conta_contabil,
    selectedConta.id,
  ]);

  return (
    <>
      {showModal && (
        <Modal width="1000px" height="650px">
          <ModalHeader
            style={{
              paddingBottom: '30px',
            }}
          >
            <ModalTitle
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #57069e29',
                color: '#57069e',
                paddingBottom: '10px',
              }}
            >
              Selecione a Conta Contábil
              <Button onClick={() => setShowModal(false)}>
                <RiCloseLine style={{ color: '#57069e' }} />
              </Button>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FilterConta>
              <GrupButtons>
                <div>
                  <BtnGroupContainer>
                    Filtrar por:
                    <Button
                      key="ContaContabil"
                      className={
                        tipoFiltro === 0
                          ? 'btn-group btn-group-active'
                          : 'btn-group'
                      }
                      value="0"
                      style={{
                        marginLeft: '10px',
                        borderRadius: '4px 0px 0px 4px',
                      }}
                      type="button"
                      onClick={() => {
                        setTipoFiltro(0);
                      }}
                    >
                      Conta Contábil
                    </Button>
                    <Button
                      key="reduzida"
                      className={
                        tipoFiltro === 1
                          ? 'btn-group btn-group-active'
                          : 'btn-group'
                      }
                      value="0"
                      style={{
                        color: '#fff',
                      }}
                      type="button"
                      onClick={() => {
                        setTipoFiltro(1);
                      }}
                    >
                      Reduzida
                    </Button>
                    <Button
                      key="desc"
                      className={
                        tipoFiltro === 2
                          ? 'btn-group btn-group-active'
                          : 'btn-group'
                      }
                      value="0"
                      style={{ borderRadius: '0px 4px 4px 0px' }}
                      type="button"
                      onClick={() => {
                        setTipoFiltro(2);
                      }}
                    >
                      Descrição
                    </Button>
                  </BtnGroupContainer>
                </div>

                <InputSearch
                  type="text"
                  placeholder="Pesquisar"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFiltro(e.target.value);
                  }}
                />

                {loading ? (
                  <CircularProgress
                    size={15}
                    style={{
                      color: '#57069e',
                      marginLeft: '-25px',
                      marginTop: '10px',
                      zIndex: '1',
                    }}
                  />
                ) : (
                  <BiSearch
                    style={{
                      color: '#57069e',
                      marginLeft: '-25px',
                      marginTop: '10px',
                      zIndex: '1',
                    }}
                  />
                )}
              </GrupButtons>
            </FilterConta>

            <ContainerGrid>
              <DataGrid
                className="DataGrid"
                style={{ height: '325px' }}
                rows={contas}
                columns={columns}
                pageSize={4}
                sortModel={[{ field: 'des_conta_contabil', sort: 'asc' }]}
                columnBuffer={10}
                onRowClick={onRowClick}
                autoHeight
                disableColumnMenu
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado...',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar colunas',
                }}
              />
            </ContainerGrid>

            <div className="d-flex justify-content-end">
              <ConfirmButton
                style={{
                  marginTop: '30px',
                }}
                onClick={handleConfirm}
              >
                <BsCheck style={{ marginRight: '3px', fontSize: '20px' }} />{' '}
                Selecionar
              </ConfirmButton>
            </div>
          </ModalBody>
        </Modal>
      )}
      <AlertBox>
        <div className={addAlert()}>
          <Field label="Conta Contábil" name="contaContabil">
            {({ fieldProps }) => (
              <div className="grupo">
                <Textfield
                  {...fieldProps}
                  type="number"
                  min={min}
                  max={max}
                  maxLength={maxLength}
                  step="1"
                  autoFocus
                  value={valueInput}
                  placeholder="Número conta reduzida "
                  isDisabled={isDisabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    validation(e.target.value);
                    // setValueAlt(e.target.value);
                  }}
                  style={{ textAlign: 'right' }}
                />
                <Button
                  style={{ background: '#2773ca' }}
                  type="button"
                  className="inputButtons"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <FaRegWindowRestore
                    style={{ color: '#fff', marginTop: '5px' }}
                    size={15}
                  />
                </Button>
                <Span
                  className="span"
                  value={
                    conta.conta_contabil !== ''
                      ? `${conta.conta_contabil} | ${conta.des_conta_contabil}`
                      : mensagemBusca
                  }
                />
              </div>
            )}
          </Field>
        </div>
      </AlertBox>
    </>
  );
};

export default ContaContabil;
